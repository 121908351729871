import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab, Menu, MenuItem, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Paper, Button } from '@mui/material';
import Dashboard from './dashboard';
import myobj from "./global";
import { useMqtt, MqttProvider } from "./common/mqtt_provider";


/*
export default function Dashboard() {
    return (
        <>
            <Outlet />
            <div
                onKeyUp={(e) => {
                    if (e.key === "Enter")
                        console.log('Enter has been pressed');
                }}
            />
        </>
    );
}
*/

function TabHolder() {
  const [pages, setPages] = useState([
    { id: 'sad1', component: <Dashboard key="sad1" visible={true} />, title: 'Tab 1' },
    { id: 'sad2', component: <Dashboard key="sad2" visible={false} />, title: 'Tab 2' }
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey) {
        if (event.key === 'R') {
          event.preventDefault();
          setRenameDialogOpen(true);
          setNewTitle(pages[selectedIndex].title);
        } else if (event.key === 'T') {
          event.preventDefault();
          addNewTab();
        } else if (event.key === 'W') {
          event.preventDefault();
          closeCurrentTab();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [pages, selectedIndex]);

  const handleTabClick = (event, newIndex) => {
    setSelectedIndex(newIndex);
    setPages(pages.map((page, i) => ({
      ...page,
      component: React.cloneElement(page.component, { visible: i === newIndex })
    })));
  };

  const handleRightClick = (event, index) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    setRenameDialogOpen(true);
    setNewTitle(pages[selectedIndex].title);
    handleCloseMenu();
  };

  const handleDelete = () => {
    closeCurrentTab();
    handleCloseMenu();
  };

  const handleRenameSubmit = () => {
    setPages(pages.map((page, i) => i === selectedIndex ? { ...page, title: newTitle } : page));
    setRenameDialogOpen(false);
    setNewTitle('');
  };

  const handleRenameCancel = () => {
    setRenameDialogOpen(false);
    setNewTitle('');
  };

  const addNewTab = () => {
    const newTab = {
      id: `new${pages.length + 1}`,
      component: <Dashboard key={`new${pages.length + 1}`} visible={true} />,
      title: `Tab ${pages.length + 1}`
    };
    setPages([...pages.map(page => ({ ...page, component: React.cloneElement(page.component, { visible: false }) })), newTab]);
    setSelectedIndex(pages.length);
  };

  const closeCurrentTab = () => {
    if (pages.length > 1) {
      const newPages = pages.filter((_, i) => i !== selectedIndex);
      setPages(newPages);
      setSelectedIndex(selectedIndex === 0 ? 0 : selectedIndex - 1);
    }
  };

  return (
    <Box height="100vh" width="100vw" display="flex" flexDirection="column">
      {/* Top Box (Flex Container) */}
      {/*<MqttProvider >*/}
      <Box flex="1" display="flex" flexDirection="column">
        {pages.map(page => page.component)}
      </Box>
      {/*</MqttProvider>*/}
      <Paper
        elevation={3}
        square
        sx={{ height: '40px', display: 'none' }} // Adjust height here
      >
        <Tabs
          value={selectedIndex}
          onChange={handleTabClick}
          aria-label="tmux-like tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ minHeight: '40px' }} // Adjust height here
        >
          {pages.map((page, index) => (
            <Tab
              key={page.id}
              label={page.title}
              onContextMenu={(e) => handleRightClick(e, index)}
              sx={{ minHeight: '40px' }} // Adjust height here
            />
          ))}
        </Tabs>
      </Paper>

      {/* Context Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleRename}>Rename</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      {/* Rename Dialog */}
      <Dialog open={renameDialogOpen} onClose={handleRenameCancel}>
        <DialogTitle>Rename Tab</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Tab Name"
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRenameSubmit} color="primary">
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TabHolder;

