import React, { useEffect, useState } from 'react';
import CommonDialog from '../../../Dialogs/CommonDialog';
import {
  Box,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Grid,
  IconButton,
  Stack
} from '@mui/material';
import PropertiesPhysicalTable from '../../properties/physical_table_box';
import EditIcon from '@mui/icons-material/Edit';
import SelectFieldDialog from '../../../Dialogs/SelectFieldDialog';
import axios from 'axios';
import { useSnackbar } from '../../../common/error_provider';
import LoadingBackdrop from '../../../common/loading_backdrop';
export default function CRMCreateWarehouse({ open, onClose, onNew }) {
  const [selectedOption, setSelectedOption] = useState('create');
  const [formData, setFormData] = useState({});
  const [showCitySelector, setShowCitySelector] = useState(false);
  const { showError, showInfo } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const handleSave = () => {
    setSaving(true);
    switch (selectedOption) {
      case 'create': {
        axios.post('/Office/CRM/Warehouses/create', formData)
          .then((response) => {
            if (response.status === 200) {
              axios.get(`/Office/CRM/Warehouses/${response.data.id}`)
                .then((response2) => {
                  if (response2.status === 200) {
                    onNew(response2.data);
                    setSaving(false);
                    onClose();
                  }
                }).catch((error) => {

                });
            }
          })
          .catch((error) => {
            setSaving(false);
            showError(`Failed to create new warehouse, ${error.status}`);
          })
        break;
      }
      case 'copy': {
        axios.post(`/Office/CRM/Warehouses/from_pms/${formData.id}`)
          .then((response) => {
            if (response.status === 200) {
              axios.get(`/Office/CRM/Warehouses/${response.data.id}`)
                .then((response2) => {
                  if (response2.status === 200) {
                    onNew(response2.data);
                    onClose();
                  }
                })
                .catch((error2) => { });
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              onClose();
            }
          });
        break;
      }
      case 'person': {

      }
    }
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  return (
    <CommonDialog title="Create New Warehouse" open={open} onClose={onClose} onSave={handleSave}>
      <Box sx={{ my: 3, textAlign: 'center' }}>
        <ToggleButtonGroup
          color="primary"
          value={selectedOption}
          exclusive
          onChange={handleOptionChange}
          aria-label="Warehouse operations"
        >
          <ToggleButton value="create" aria-label="create">
            Create New Property
          </ToggleButton>
          <ToggleButton value="copy" aria-label="copy">
            Copy from PMS System
          </ToggleButton>
          {/*<ToggleButton value="person" aria-label="person">
            marginake it a Person
          </ToggleButton>*/}
        </ToggleButtonGroup>
      </Box>
      <Divider sx={{ my: 3 }} />
      {selectedOption === 'create' && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Property Name"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="House Name"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setFormData({ ...formData, house_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Street"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setFormData({ ...formData, street: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction='row'>
                <TextField
                  label="City"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.city_label}
                  inputProps={{
                    readOnly: true,
                  }}
                />
                <IconButton onClick={() => setShowCitySelector(true)}><EditIcon /></IconButton>
              </Stack>
            </Grid>
            {/*<Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                fullWidth
                margin="normal"
                variant="outlined"
              />
          </Grid>*/}
            <Grid item xs={12}>
              <TextField
                label="Postal Code"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setFormData({ ...formData, postal_code: e.target.value })}
              />
            </Grid>
          </Grid>
        </Box>
      )
      }

      {
        selectedOption === 'copy' && (
          <Box>
            <PropertiesPhysicalTable onSelect={(item) => setFormData(item)} />
          </Box>
        )
      }

      {
        selectedOption === 'person' && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Make it a Person
            </Typography>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Example data, replace with actual data */}
                  <TableRow>
                    <TableCell>John</TableCell>
                    <TableCell>Doe</TableCell>
                    <TableCell>john.doe@example.com</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Jane</TableCell>
                    <TableCell>Smith</TableCell>
                    <TableCell>jane.smith@example.com</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button variant="contained" color="primary" size="large">
                Select Person
              </Button>
            </Box>
          </Box>
        )
      }
      <SelectFieldDialog open={showCitySelector} onClose={() => setShowCitySelector(false)}
        title="Select a city" url={'/Office/Location/byID/31'}
        keyField="id"
        valueField='location_name'
        onSave={(item) => {
          setFormData({ ...formData, city_label: item.location_name, city_id: item.id });
        }}
      />
      <LoadingBackdrop open={saving} text="Saving warehouse" />
    </CommonDialog >
  );
}
