import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
  IconButton,
  Paper,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import Editor from '@monaco-editor/react';

const PhaseEditorDialog = ({ open, onClose }) => {
  const [phaseData, setPhaseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jsonEditorOpen, setJsonEditorOpen] = useState(false);
  const [jsonEditorIndex, setJsonEditorIndex] = useState(null);
  const [jsonEditorContent, setJsonEditorContent] = useState('');

  useEffect(() => {
    if (open) {
      setLoading(true);
      axios
        .get('/LeviathanMonitoring/DCIS/7')
        .then((response) => {
          setPhaseData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, [open]);

  const handleUpdatePhase = (index, field, value) => {
    const updatedPhases = [...phaseData.phases];
    updatedPhases[index][field] = value;
    setPhaseData({ ...phaseData, phases: updatedPhases });
  };

  const handleOpenJsonEditor = (index) => {
    setJsonEditorIndex(index);
    setJsonEditorContent(JSON.stringify(phaseData.phases[index].phase_command.args, null, 2));
    setJsonEditorOpen(true);
  };

  const handleSaveJsonEditor = () => {
    try {
      const parsedJson = JSON.parse(jsonEditorContent);
      handleUpdatePhase(jsonEditorIndex, 'phase_command.args', parsedJson);
      setJsonEditorOpen(false);
    } catch (error) {
      alert('Invalid JSON format');
    }
  };

  const handleAddPhase = () => {
    const newPhase = {
      id: Date.now().toString(),
      phase_command: { method: '', args: {} },
      phase_order: phaseData.phases.length,
      phase_enabled: true,
    };
    setPhaseData({ ...phaseData, phases: [...phaseData.phases, newPhase] });
  };

  const handleDeletePhase = (index) => {
    const updatedPhases = phaseData.phases.filter((_, i) => i !== index);
    setPhaseData({ ...phaseData, phases: updatedPhases });
  };

  const handleSave = () => {
    axios
      .post('/LeviathanMonitoring/DCIS/7', phaseData)
      .then(() => {
        alert('Saved successfully!');
        onClose();
      })
      .catch((err) => alert('Error saving data: ' + err.message));
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Phases</DialogTitle>
        <DialogContent dividers>
          {loading && <CircularProgress />}
          {error && <Typography color="error">Error: {error.message}</Typography>}
          {phaseData && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Method</TableCell>
                    <TableCell>Args</TableCell>
                    <TableCell>Order</TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {phaseData.phases.map((phase, index) => (
                    <TableRow key={phase.id}>
                      <TableCell>{phase.id}</TableCell>
                      <TableCell>
                        <TextField
                          value={phase.phase_command.method}
                          onChange={(e) => handleUpdatePhase(index, 'phase_command.method', e.target.value)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => handleOpenJsonEditor(index)}
                          startIcon={<Edit />}
                        >
                          Edit JSON
                        </Button>
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={phase.phase_order}
                          onChange={(e) => handleUpdatePhase(index, 'phase_order', e.target.value)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={phase.phase_enabled}
                          onChange={(e) => handleUpdatePhase(index, 'phase_enabled', e.target.checked)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeletePhase(index)} color="error">
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddPhase} startIcon={<Add />} color="primary">
            Add Phase
          </Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* JSON Editor Dialog */}
      <Dialog open={jsonEditorOpen} onClose={() => setJsonEditorOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit JSON Arguments</DialogTitle>
        <DialogContent dividers>
          <Editor
            height="300px"
            language="json"
            value={jsonEditorContent}
            onChange={(value) => setJsonEditorContent(value)}
            options={{
              minimap: { enabled: false },
              formatOnPaste: true,
              formatOnType: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setJsonEditorOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveJsonEditor} variant="contained" color="primary">
            Save JSON
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PhaseEditorDialog;

