import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  Grid, TableRow, TableCell,
  Tabs, Tab,
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../../css/common_sx';
import Refresh from '@mui/icons-material/Refresh';
import { TableGrid } from '../../../common/TableGrid';
import { NoneManagedCommonDialog } from '../../../Dialogs/CommonDialog';
import { RemoveRounded, TabUnselectedOutlined, TenMpOutlined } from '@mui/icons-material';
import LoadingBackdrop from '../../../common/loading_backdrop';
import AddRounded from '@mui/icons-material/AddRounded';
import _ from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';
import { useSnackbar } from '../../../common/error_provider';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CRMWarehousesDialog from '../pick_warehouse';
export default function OrderWorkFlowMovementTo({ open, orderWorkFlowID, workflowItemID, onClose, onSave }) {
  const { showError, showInfo } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showWarehouseDialog, setShowWarehouseDialog] = useState(false);
  const [warehouseType, setWarehouseType] = useState('');
  const [warehouseTags, setWarehouseTags] = useState('');
  const fetchData = () => {
    setLoading(true);
    axios.get(`/Office/CRM/OrderWorkflows/WorkflowItems/${orderWorkFlowID}/getUseableData/${workflowItemID}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    if (!open) return;
    fetchData();
  }, [open])

  const openWarehouseDialog = (type) => {
    setWarehouseType(type);
    if (type === 'dst_warehouse') {
      setWarehouseTags(data.dst_warehouse_tags);
    } else {
      setWarehouseTags(null);
    }
    setShowWarehouseDialog(true);
  };

  const hadleSave = () => {
    if (data['src_warehouse'] === null) {
      showError('Please select a source warehouse');
      return;
    }
    if (data['dst_warehouse'] === null) {
      showError('Please select a destination warehouse');
      return;
    }
    let items = []
    for (const item of data['items']) {
      items.push({
        item_id: item.item_id,
        item_qty: -item.item_qty,
        warehouse: data['src_warehouse'].warehouse_id,
      });
      items.push({
        item_id: item.item_id,
        item_qty: item.item_qty,
        warehouse: data['dst_warehouse'].warehouse_id
      })
    }
    axios.post(`/Office/CRM/DocumentItems/-1?create_document=1&create_store_items=true`, {
      header: {
        document_type_id: 2,
        source_warehouse: data['src_warehouse'].warehouse_id,
        destination_warehouse: data['dst_warehouse'].warehouse_id,
      },
      items: items
    })
      .then((res) => {
        showInfo('Order moved successfully');
        onSave({
          document_id: res.data.document_id,
        });
        onClose();
      })
      .catch((err) => {
        showError(err)
      })
  }
  return (
    <NoneManagedCommonDialog
      open={open} onClose={onClose}
      onSave={hadleSave}
      title="Move Order"
    >
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='row' justifyContent='space-between' mb={1}>
          <Box>
            <Typography variant="body1">
              Move From:
            </Typography>
            <Typography variant="body2">
              {data['src_warehouse']?.warehouse_name || 'No selection'}
            </Typography>
            <Typography variant="body2">
              {data['src_warehouse']?.warehouse_address || ''}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            disabled={data['src_warehouse'] !== null}
            onClick={() => openWarehouseDialog('deliveryFrom')}
            style={{ marginLeft: 10 }}
          >
            {'deliveryFrom' === 'deliveryFrom' ? 'Choose Warehouse' : 'Choose Client'}
          </Button>
          <Box>
            <Typography variant="body1">
              Move To:
            </Typography>
            <Typography variant="body2">
              {data['dst_warehouse']?.warehouse_name || 'No selection'}
            </Typography>
            <Typography variant="body2">
              {data['dst_warehouse']?.warehouse_address || ''}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openWarehouseDialog('dst_warehouse')}
            style={{ marginLeft: 10 }}
          >
            {'deliveryFrom' === 'deliveryFrom' ? 'Choose Warehouse' : 'Choose Client'}
          </Button>
        </Box>
        <TableGrid enableOuterShell={false} rowsPerPage={10} loading={loading} columns={['Item Name', 'SKU', 'Qty']}>
          {data['items']?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.item_name}</TableCell>
              <TableCell>{item.item_sku}</TableCell>
              <TableCell>{item.item_qty}</TableCell>
            </TableRow>
          ))}
        </TableGrid>
      </Box>
      <LoadingBackdrop open={loading} />
      <CRMWarehousesDialog
        open={showWarehouseDialog}
        onClose={() => setShowWarehouseDialog(false)}
        onSelect={(item) => {
          if (warehouseType === 'dst_warehouse') {
            setData({
              ...data, dst_warehouse: {
                warehouse_id: item.id,
                warehouse_name: item.name,
                warehouse_address: item.full_address,
              }
            });
          }
        }}
        withTags={warehouseTags}
      />
    </NoneManagedCommonDialog >
  )
}
