import React, { useEffect, useState } from 'react';
import {
  Box,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Checkbox,
  Stack
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { TableGrid } from '../../common/TableGrid';
import { orangeColor } from '../../css/common_sx';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Info from '@mui/icons-material/Info';
import PhaseEditorDialog from './dci/editor';
export default function MonitoringDCI({ visible, device }) {
  const [dcis, setDcis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPhaseEditor, setShowPhaseEditor] = useState(false);
  const fetchDcis = async () => {
    setLoading(true);
    axios.get(`/LeviathanMonitoring/Device/DCI/${device.id}`)
      .then((res) => {
        setDcis(res.data);
        setLoading(false);
      }).catch((error) => {
        console.error('Error fetching DCIs:', error);
      });
  };

  useEffect(() => {
    if (!visible) return;
    fetchDcis();
  }, [visible, device]);

  const columns = [
    'ID',
    'Name',
    'Last Pool Time',
    'Enabled',
    'Redo Time',
    {
      label: 'Actions',
      actions: [
        { icon: AddIcon, onClick: () => console.log('Add DCI') },
      ]
    }
  ]

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Box display="flex" flexDirection="column" width="100%" height="100%" flex={2}>
        <TableGrid columns={columns} loading={loading}>
          {
            dcis.map((dci) => (
              <TableRow key={dci.org_id}>
                <TableCell>{dci.org_id}</TableCell>
                <TableCell>{dci.dci_name}</TableCell>
                <TableCell>{dci.last_pool_time}</TableCell>
                <TableCell></TableCell>
                <TableCell>{dci.reschedule_timeout_sec}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      onClick={() => { setShowPhaseEditor((true)) }}
                      size="small"
                      color="primary"
                      sx={{ padding: 0 }}
                    >
                      <Info fontSize='small' />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))
          }
        </TableGrid>
      </Box>
      <PhaseEditorDialog open={showPhaseEditor} onClose={() => setShowPhaseEditor(false)} />
    </Box>
  );
}
