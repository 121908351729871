import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  TableRow,
  TableCell,
  TablePagination,
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../css/common_sx';
import CRMOrderDetails from './orders/order_details';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import CRMCreateNewOrder from './orders/create_new_order';
import { TableGrid } from '../../common/TableGrid';
import { debounce } from 'lodash';
import { searchFunction } from '../../common/search_function';
import { useSnackbar } from '../../common/error_provider';
export default function CRMOrders() {
  const [globalFilter, setGlobalFilter] = useState('');
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); // Rows per page
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [openCreateOrder, setOpenCreateOrder] = useState(false);
  const { showError } = useSnackbar();
  const fetchOrders = async () => {
    setLoading(true);
    axios.get('/Office/CRM/Orders')
      .then((response) => {
        setOrders(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        showError('Error fetching orders');
      });

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    'Order ID',
    'Order Type',
    'Order Priority',
    'Order Date (Per Local)',
    'Order Warehouse',
    'Customer',
    'Partner Reference',
    'Workflow Name',
    'Workflow Status',
    'Order Fulfill Date',
    'Order Confirmed',
    'Order Canceled',
    'Actions'
  ];

  const filteredOrders = useMemo(() => {
    return searchFunction(globalFilter, ['order_id', 'order_type', 'order_status', 'order_priority',
      'created_at', 'warehouse_name', 'partner_name', 'order_fill_date', 'order_partner_ref'], orders);
  }, [globalFilter, orders]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalFilter(value);
    }, 300), []);

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={1} mb={1} borderRadius={2} boxShadow={3} bgcolor={orangeColor}>
        <TextField
          label={`Search`}
          onChange={(e) => handleSearchChange(e.target.value)}
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: 1 }}
        />
        <Button variant="contained" color="primary" sx={{ marginLeft: 1 }} startIcon={<AddIcon />} onClick={() => {
          setOpenCreateOrder(true);
        }} >
          Create New Order
        </Button>
      </Box>
      <TableGrid columns={columns} loading={loading} rowsPerPage={rowsPerPage} >
        {filteredOrders
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((order) => (
            <TableRow key={order.id}>
              <TableCell>{order.id}</TableCell>
              <TableCell>{order.order_type_name}</TableCell>
              <TableCell>
                <Box color={parseInt(order.order_priority) == 50 ? 'green' : 'red'}>
                  {order.order_priority}
                </Box>
              </TableCell>
              <TableCell>{new Date(order.created_at).toLocaleString()}</TableCell>
              <TableCell>{order.warehouse_name}</TableCell>
              <TableCell>{order.partner_name}</TableCell>
              <TableCell>{order.order_partner_ref}</TableCell>
              <TableCell>{order.workflow_name}</TableCell>
              <TableCell>{order.workflow_status}</TableCell>
              <TableCell>{order.order_fill_date ? new Date(order.order_fill_date).toLocaleString() : ''}</TableCell>
              <TableCell>{order.confirmed ? new Date(order.confirmed).toLocaleString() : ''}</TableCell>
              <TableCell>{order.canceled ? new Date(order.canceled).toLocaleString() : ''}</TableCell>
              <TableCell>
                <IconButton sx={{ padding: 0 }} onClick={() => {
                  setSelectedOrder(order.id);
                  setOpenOrderDetails(true);
                }} ><InfoIcon fontSize='small' /></IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableGrid>
      <CRMCreateNewOrder open={openCreateOrder}
        onClose={() => setOpenCreateOrder(false)}
        onNew={(item) => {
          setOrders([item, ...orders]);
        }} />
      <CRMOrderDetails orderID={selectedOrder} open={openOrderDetails} onClose={() => setOpenOrderDetails(false)} />
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={{ ...CommonSX, mt: 1 }}
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
};
