import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Divider,
  IconButton,
  Autocomplete,
  Box,
  Paper,
  Typography,
  TableCell,
  TableRow,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "axios";
import PartnerPicker from "../partners_picker";
import { TableGrid } from "../../../common/TableGrid";
const InvoiceDialog = () => {

  const [formData, setFormData] = useState({
    invoice_header: {
      partner_id: "",
      partner_name: "",
      invoice_no: "",
      partner_reference: "",
      partner_create_date: "",
      partner_issue_date: "",
      partner_fill_date: "",
      full_fill_at: "",
      devisa: "",
    },
    invoice_items: [
      {
        item_id: "",
        item_unit_net: "",
        item_unit_vat: "",
        item_qty: "",
      },
    ],
  });

  const [showPartnerPicker, setShowPartnerPicker] = useState(false);
  const [items, setItems] = useState([]);

  const fetchItems = () => {
    try {
      axios.get("/Office/Accounts/Items")
        .then((response) => {
          setItems(response.data);
        })
        .catch((error) => { });
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }

  const handleInputChange = (section, key, value, index = null, onStateUpdated = null) => {
    if (index !== null) {
      const updatedItems = [...formData.invoice_items];
      updatedItems[index][key] = value;
      setFormData({ ...formData, invoice_items: updatedItems });
    } else {
      setFormData({
        ...formData,
        [section]: { ...formData[section], [key]: value },
      });
    }
    //If not all items has an item_id then create a new empty item
    !formData.invoice_items.some((item) => item.item_id === "") && addItem();

  };

  const addItem = () => {
    setFormData({
      ...formData,
      invoice_items: [
        ...formData.invoice_items,
        {
          item_id: "",
          item_unit_net: "",
          item_unit_vat: "",
          item_qty: "",
        },
      ],
    });
  };

  const removeItem = (index) => {
    const updatedItems = formData.invoice_items.filter((_, i) => i !== index);
    setFormData({ ...formData, invoice_items: updatedItems });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const calculateTotalGross = () => {
    return formData.invoice_items.reduce((total, item) => {
      const unitNet = parseFloat(item.item_unit_net) || 0;
      const qty = parseFloat(item.item_qty) || 0;
      return total + unitNet * qty;
    }, 0);
  };

  const handleSave = () => {
    if (formData.invoice_header.partner_id === "") {
      alert("Please select a partner");
      return;
    }
    console.log("Saving invoice:", formData);
    axios.post("/Office/Accounts/Invoices", formData)
      .then((response) => {
        if (response.status.toString() === "200") {
          setFormData({
            ...formData,
            invoice_header: {
              ...formData.invoice_header,
              invoice_id: response.data.id
            }
          });
        }
        console.log("Invoice saved:", response.data);
      })
      .catch((error) => { });
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1}>
      <Paper sx={{ padding: 1, display: 'flex', flexDirection: 'row' }}>
        <Box flex={1}>
          <Typography>Create New Invoice</Typography>
        </Box>
        <Box flex={1} alignItems='end' justifyContent='end'>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Box>
      </Paper>
      <Divider sx={{ my: 2, backgroundColor: "#FF5722" }} />

      {/* Invoice Header */}
      <Paper sx={{ padding: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              label="Partner"
              value={formData.invoice_header.partner_name}
              inputProps={{ readOnly: true }}
              onClick={() => setShowPartnerPicker(true)}
              sx={{ backgroundColor: "#FFFFFF", borderRadius: 1 }}
            />
            <IconButton
              onClick={() => setShowPartnerPicker(true)}
              sx={{ color: "#FF5722" }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Invoice No"
              value={formData.invoice_header.invoice_no}
              onChange={(e) =>
                handleInputChange("invoice_header", "invoice_no", e.target.value)
              }
              sx={{ backgroundColor: "#FFFFFF", borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Partner Reference"
              value={formData.invoice_header.partner_reference}
              onChange={(e) =>
                handleInputChange(
                  "invoice_header",
                  "partner_reference",
                  e.target.value
                )
              }
              sx={{ backgroundColor: "#FFFFFF", borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="date"
              label="Partner Create Date"
              InputLabelProps={{ shrink: true }}
              value={formData.invoice_header.partner_create_date}
              onChange={(e) =>
                handleInputChange(
                  "invoice_header",
                  "partner_create_date",
                  e.target.value
                )
              }
              sx={{ backgroundColor: "#FFFFFF", borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="date"
              label="Partner Issue Date"
              InputLabelProps={{ shrink: true }}
              value={formData.invoice_header.partner_issue_date}
              onChange={(e) =>
                handleInputChange(
                  "invoice_header",
                  "partner_issue_date",
                  e.target.value
                )
              }
              sx={{ backgroundColor: "#FFFFFF", borderRadius: 1 }}
            />
          </Grid>
          {/* Additional fields can go here */}
        </Grid>
      </Paper>
      <Divider sx={{ my: 2, backgroundColor: "#FF5722" }} />

      {/* Bottom Section */}
      <Box sx={{ display: "flex", flexDirection: 'row', gap: 2, height: '100%' }}>
        {/* Table for Invoice Items */}
        <Box sx={{ flex: 3 }}>
          <TableGrid columns={["Item Name", "Unit Net", "Unit VAT", "Quantity", "Total Gross", "Actions"]} loading={false} >
            {formData.invoice_items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Autocomplete
                    size="small"
                    options={items}
                    getOptionLabel={(option) => option.item_name || ""}
                    renderOption={(props, options) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Box
                          key={key}
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...optionProps}
                        >
                          (CRM) {options.item_name}
                        </Box>
                      );
                    }}
                    value={
                      items.find(
                        (opt) => parseInt(opt.id) === parseInt(item.item_id)
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleInputChange(
                        "invoice_items",
                        "item_id",
                        newValue ? newValue.id : "",
                        index,
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Item Name" />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.item_unit_net}
                    onChange={(e) =>
                      handleInputChange(
                        "invoice_items",
                        "item_unit_net",
                        e.target.value,
                        index
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.item_unit_vat}
                    onChange={(e) =>
                      handleInputChange(
                        "invoice_items",
                        "item_unit_vat",
                        e.target.value,
                        index
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.item_qty}
                    onChange={(e) =>
                      handleInputChange(
                        "invoice_items",
                        "item_qty",
                        e.target.value,
                        index
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  {(parseFloat(item.item_unit_net || 0) *
                    parseFloat(item.item_qty || 0)).toFixed(2)}
                </TableCell>
                <TableCell>
                  <Button
                    color="error"
                    onClick={() => removeItem(index)}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableGrid>

        </Box>

        {/* Summary Section */}
        <Box sx={{ flex: 1, height: '100%', display: "flex", flexDirection: "column", gap: 2 }}>
          <Paper sx={{ flex: 2, padding: 2, height: '100%' }}>
            <Typography variant="h6">Payments</Typography>
            {/* Add payment-related fields here */}
          </Paper>
          <Paper sx={{ flex: 1, padding: 2 }}>
            <Typography variant="h6">Summary</Typography>
            <Typography>Total Gross: {calculateTotalGross().toFixed(2)}</Typography>
            <Typography>Total Unpaid: {/* Add calculation */}</Typography>
          </Paper>
        </Box>
      </Box>

      <PartnerPicker
        open={showPartnerPicker}
        onClose={() => setShowPartnerPicker(false)}
        defaultValue={formData.invoice_header.partner_search_name ?? ""}
        onSelect={(partner) => {
          setFormData({
            ...formData,
            invoice_header: {
              ...formData.invoice_header,
              partner_id: partner.id,
              partner_name: partner.partner_name,
            },
          });
          setShowPartnerPicker(false);
        }}
      />
    </Box>
  );
};

export default InvoiceDialog;

