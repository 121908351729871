import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  TableCell,
  TableRow,
  TablePagination,
  Typography,
  LinearProgress,
  IconButton
} from '@mui/material';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { orangeColor } from '../../css/common_sx';
import DocumentEditorDialog from './warehouse_documet_allowers';
import { searchFunction } from '../../common/search_function';
import { TableGrid } from '../../common/TableGrid';
import { CommonSX } from '../../css/common_sx';
import CRMCreateWarehouse from './warehouses/create_new_warehouse';
import EditWarehouse from './warehouses/edit_warehouse';

export default function CRMWarehouses() {
  const [globalFilter, setGlobalFilter] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [createWarehouseDialog, setCreateWarehouseDialog] = useState(false);
  const [typeAccesses, setTypeAccesses] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showEditWarehouse, setShowEditWarehouse] = useState(false);

  useEffect(() => {
    axios.get('/Office/CRM/Warehouses')
      .then((response) => {
        setWarehouses(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Failed to fetch data');
        setLoading(false);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredWarehouses = searchFunction(globalFilter, ['name', 'full_address', 'capacity', 'current_storage'], warehouses);


  const columns = [
    'ID',
    'Type',
    'Partner',
    'Name',
    'Address',
    'Capacity',
    'Used Capacity',
    'Actions'
  ]

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={1}
        bgcolor={orangeColor}  // Make sure `orangeColor` is defined as a valid color
        padding={1}  // Add padding for better spacing inside the box
        borderRadius={2}  // Optional: Adds a rounded border for a more modern look
        boxShadow={3}  // Optional: Adds a subtle shadow for depth
      >
        <TextField
          label="Search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          fullWidth
          sx={{
            bgcolor: 'white',  // White background to contrast with the box color
            borderRadius: 1,  // Adds a slight rounding to the TextField
            marginRight: 2,  // Space between the TextField and Button
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={(res) => {
            setCreateWarehouseDialog(true);
          }}
          sx={{
            textTransform: 'none',  // Keeps the text in normal casing
            paddingLeft: 2,
          }}
        >
          Add Item
        </Button>
        {/*<Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setTypeAccesses(true)}
          sx={{
            textTransform: 'none',  // Keeps the text in normal casing
            marginLeft: 2,  // Space between the TextField
          }}
        >
          Warehouse Allows
        </Button> */}
      </Box>
      <TableGrid columns={columns} rowsPerPage={rowsPerPage} loading={loading} >
        {
          filteredWarehouses
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(warehouse => (
              <TableRow key={warehouse.id}>
                <TableCell>{warehouse.id}</TableCell>
                <TableCell>{warehouse.type}</TableCell>
                <TableCell>{warehouse.partner_name}</TableCell>
                <TableCell>{warehouse.name}</TableCell>
                <TableCell>{warehouse.full_address || 'N/A'}</TableCell>
                <TableCell>{warehouse.capacity || 'N/A'}</TableCell>
                <TableCell>
                  {warehouse.current_storage ? (
                    <Box display="flex" alignItems="center">
                      <Box width="100%" mr={1}>
                        <LinearProgress
                          variant="determinate"
                          value={(warehouse.current_storage / warehouse.capacity) * 100}
                        />
                      </Box>
                      <Typography variant="body2" color="textSecondary">{`${Math.round(
                        (warehouse.current_storage / warehouse.capacity) * 100
                      )}%`}</Typography>
                    </Box>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    sx={{
                      padding: 0, // Remove extra padding
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setSelectedItem(warehouse.id);
                      setShowEditWarehouse(true);
                    }}>
                    <InfoIcon fontSize='small' />
                  </IconButton>
                </TableCell>
              </TableRow>

            ))
        }
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        sx={{ ...CommonSX, marginTop: '10px' }}
        component="div"
        count={warehouses.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
      />
      <CRMCreateWarehouse
        open={createWarehouseDialog}
        onClose={() => setCreateWarehouseDialog(false)}
        onNew={(item) => {
          setWarehouses([item, ...warehouses]);
        }}
      />

      <DocumentEditorDialog open={typeAccesses} onClose={() => setTypeAccesses(false)} />
      <EditWarehouse
        open={showEditWarehouse}
        onClose={() => setShowEditWarehouse(false)}
        warehouse={selectedItem}
        onEdit={(item) => {
          setWarehouses([...warehouses.map(wh => wh.id === item.id ? item : wh)]);
        }}
      />
    </Box>
  );
}

