import React, { useEffect, useState } from 'react';
import CommonDialog from '../../../Dialogs/CommonDialog';
import {
  Box,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Grid,
  IconButton,
  Stack,
  Alert
} from '@mui/material';
import PropertiesPhysicalTable from '../../properties/physical_table_box';
import EditIcon from '@mui/icons-material/Edit';
import SelectFieldDialog from '../../../Dialogs/SelectFieldDialog';
import axios from 'axios';
import { useSnackbar } from '../../../common/error_provider';
import LoadingBackdrop from '../../../common/loading_backdrop';
import { CRMPartnerGridWithSearchDialog } from '../partners/partner_grid_with_search';
export default function EditWarehouse({ open, onClose, warehouse, onEdit }) {
  const [warehouseData, setWarehouseData] = useState({});
  const { showError, showInfo } = useSnackbar();
  const [showPartnerDialog, setShowPartnerDialog] = useState(false);

  useEffect(() => {
    if (open) {
      setWarehouseData({});
      axios.get(`/Office/CRM/Warehouses/${warehouse}`)
        .then((response) => {
          setWarehouseData(response.data);
        })
        .catch((error) => {
          showError('Failed to fetch warehouse data');
        });
    }
  }, [open]);

  const handleSave = () => {
    axios.put(`/Office/CRM/Warehouses/${warehouse}`, warehouseData)
      .then((response) => {
        if (response.status === 200) {
          onEdit(response.data);
          onClose();
          showInfo('Warehouse updated successfully');
        }
      })
      .catch((error) => {
        showError('Failed to update warehouse');
      });

  };

  return (
    <CommonDialog title="Edit Warehouse" open={open} onClose={onClose} onSave={handleSave}>
      <Typography bold variant="h4">Warehouse Details</Typography>
      {!warehouseData.editable && (
        <Alert severity="info" mb={5}>
          Warehouse General Data is not Editable (Mapped)
        </Alert>
      )}
      <Grid container spacing={2} mb={1} mt={1}>
        <Grid item xs={6}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={warehouseData.name || ""}
            disabled={!warehouseData.editable}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="House Name"
            variant="outlined"
            fullWidth
            value={warehouseData.house_name || ""}
            disabled={!warehouseData.editable}
            onChange={(e) => setWarehouseData({ ...warehouseData, house_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Postal Code"
            variant="outlined"
            fullWidth
            value={warehouseData.postal_code || ""}
            disabled={!warehouseData.editable}
            onChange={(e) => setWarehouseData({ ...warehouseData, postal_code: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Street"
            variant="outlined"
            fullWidth
            value={warehouseData.street || ""}
            onChange={(e) => setWarehouseData({ ...warehouseData, street: e.target.value })}
            disabled={!warehouseData.editable}
          />
        </Grid>
      </Grid>
      <Divider />
      <Stack direction='row'><Typography bold variant="h4">Partner Details</Typography><IconButton onClick={() => setShowPartnerDialog(true)}><EditIcon /></IconButton></Stack>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={warehouseData.partner_name || ""}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={warehouseData.partner_email || ""}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Phone"
            variant="outlined"
            fullWidth
            value={warehouseData.partner_phone || ""}
            disabled
          />
        </Grid>
      </Grid>
      <CRMPartnerGridWithSearchDialog open={showPartnerDialog} onClose={() => setShowPartnerDialog(false)} onSelect={(partner) => {
        if (partner) {
          setWarehouseData({ ...warehouseData, partner_id: partner.id, partner_name: partner.partner_name, partner_email: partner.partner_email, partner_phone: partner.partner_phone });
          setShowPartnerDialog(false);
        }
      }} />
    </CommonDialog>
  )
}
