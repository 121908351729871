import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Typography,
  Snackbar,
  Alert,
  LinearProgress,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import axios from 'axios';
import { searchFunction } from '../../common/search_function';
import CommonDialog from '../../Dialogs/CommonDialog';
import { TableGrid } from '../../common/TableGrid';
export default function CRMWarehousesDialog({ open, onClose, withTags = null, onSelect }) {
  const [globalFilter, setGlobalFilter] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Fetch warehouse data when the dialog opens
  useEffect(() => {
    if (open) {
      setLoading(true);
      axios.get(`/Office/CRM/Warehouses${(withTags !== null) ? `?tags=${withTags}` : ''}`)
        .then((response) => {
          setWarehouses(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError('Failed to fetch data');
          setLoading(false);
        });
    }
  }, [open]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Close dialog and pass selected item
  const handleClose = useCallback((item) => {
    onSelect(item);
    onClose(item);
  }, [onClose]);

  const filteredWarehouses = searchFunction(globalFilter, ['name', 'full_address'], warehouses);


  return (
    <CommonDialog
      open={open}
      onClose={() => handleClose(null)}
      title="Warehouse Selection"
      enableSave={false}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <TextField
          label="Search"
          onChange={(e) => setGlobalFilter(e.target.value)}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
        <TableGrid loading={loading}
          columns={['ID', 'Name', 'Full Address', 'Capacity', 'Storage', 'Actions']}
          rowsPerPage={rowsPerPage}
          enableOuterShell={false}
        >
          {
            filteredWarehouses
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(warehouse => (
                <TableRow
                  key={warehouse.id}
                  onClick={() => handleClose(warehouse)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{warehouse.id}</TableCell>
                  <TableCell>{warehouse.name}</TableCell>
                  <TableCell>{warehouse.full_address || 'N/A'}</TableCell>
                  <TableCell>{warehouse.capacity || 'N/A'}</TableCell>
                  <TableCell>
                    {warehouse.current_storage ? (
                      <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <LinearProgress
                            variant="determinate"
                            value={(warehouse.current_storage / warehouse.capacity) * 100}
                          />
                        </Box>
                        <Typography variant="body2" color="textSecondary">{`${Math.round(
                          (warehouse.current_storage / warehouse.capacity) * 100
                        )}%`}</Typography>
                      </Box>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              ))
          }
        </TableGrid>
        <TablePagination
          rowsPerPageOptions={[20, 30, 50]}
          component="div"
          count={warehouses.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

    </CommonDialog>
  );
}

