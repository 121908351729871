import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  Grid, TableRow, TableCell,
  Tabs, Tab,
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../../css/common_sx';
import Refresh from '@mui/icons-material/Refresh';
import { TableGrid } from '../../../common/TableGrid';
import CommonDialog from '../../../Dialogs/CommonDialog';
import CRMItemsPickerDialog from '../pick_item';
import { RemoveRounded, TabUnselectedOutlined, TenMpOutlined } from '@mui/icons-material';
import LoadingBackdrop from '../../../common/loading_backdrop';
import AddRounded from '@mui/icons-material/AddRounded';
import _ from 'lodash';
import OrderWorkFlow from './order_workflows';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';
import { useSnackbar } from '../../../common/error_provider';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
export default function CRMOrderDetails({ orderID, open, onClose }) {
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({ items: [] });
  const [loading, setLoading] = useState(false);

  const [loadingItems, setLoadingItems] = useState(true);
  const [showItemsPicker, setshowItemsPicker] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editingPrices, setEditingPrices] = useState({});
  const [editingQuantities, setEditingQuantities] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showFill, setShowFill] = useState(false);

  const { showError } = useSnackbar();


  const fetchOrderDetails = async () => {
    axios.get(`/Office/CRM/Orders/${orderID}`)
      .then((response) => {
        setFormData({ ...formData, ...response.data, items: [] });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchOrderItems = async () => {
    axios.get(`/Office/CRM/Orders/${orderID}/Items`)
      .then((response) => {
        setFormData({ ...formData, items: response.data });
        setLoadingItems(false);
        console.log(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addNewItem = (item) => {
    axios.post(`/Office/CRM/Orders/${orderID}/Items`, { item_id: item.id, quantity: 1 })
      .then((response) => {
        if (response.status === 200) {
          setFormData({ ...formData, items: [...formData.items, { id: response.id, sku: item.sku, quantity: 1, item_name: item.item_name, price: 0 }] });
        }
      })
      .catch((err) => {
        showError('Error adding item to order');
      });
  };

  useEffect(() => {
    if (tabValue === 1) {
      setLoadingItems(true);
      fetchOrderItems();
    }
  }, [tabValue]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setTabValue(0);
      fetchOrderDetails();
    }
  }, [open]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSave = () => {
    setSaving(true);
    for (const item of formData.items) {
      axios.put(`/Office/CRM/Orders/${orderID}/Items/${item.id}`, { quantity: item.quantity || 1, price: item.price || 0 })
        .then((response) => {
          if (response.status === 200) {
            setSaving(false);
            onClose();
          }
        })
        .catch((err) => {
          setSaving(false);
        });
    }
  }

  const enableEdit = () => {
    if (formData.order_fill_date !== null) {
      return false;
    }
    if (formData.confirmed !== null) {
      return false;
    }
    return true;
  }

  const confirmOrder = () => {
    axios.put(`/Office/CRM/Orders/${orderID}/Confirm`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({ ...formData, ...response.data });
        }
      })
      .catch((err) => {
        showError('Error confirming order');
      });
  }

  const cancelOrder = () => {
    axios.put(`/Office/CRM/Orders/${orderID}/Cancel`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({ ...formData, ...response.data });
        }
      }).catch((err) => {
        showError('Error canceling order');
      });
  }

  const handleFillOrder = () => {
    axios.put(`/Office/CRM/Orders/${orderID}/Fill`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({ ...formData, ...response.data });
        }
      })
      .catch((err) => {
        showError('Error filling order');
      });
  }

  return (
    <CommonDialog open={open} onClose={onClose} onSave={handleSave} title={`Order Details for ${orderID}`}>
      <Box>
        {formData.canceled !== null && (
          <Alert severity="error" sx={{ marginBottom: 1 }}>Order Canceled on {new Date(formData.canceled).toLocaleString()}</Alert>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={1}
          borderColor="divider"
          sx={{ backgroundColor: 'background.paper', borderRadius: '8px' }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="order details tabs"
            sx={{ '.MuiTab-root': { textTransform: 'none', fontSize: '1rem' } }}
          >
            <Tab label="General" />
            <Tab label="Ordered Items" />
            <Tab label="Workflows" disabled={formData.confirmed === null} />
          </Tabs>

          <Box display="flex" gap={1}>
            <IconButton color="success" disabled={(() => {
              if (formData.order_fill_date !== null) return true
              if (formData.canceled !== null) return true
              if (formData.confirmed !== null) return true
              return false
            })()}
              onClick={() => setShowConfirm(true)}
            >
              <CheckIcon />
            </IconButton>
            <IconButton color="error" disabled={(() => {
              if (formData.order_fill_date !== null) return true
              if (formData.canceled !== null) return true
              return false
            })()}
              onClick={() => setShowCancel(true)}
            >
              <CancelIcon />
            </IconButton>
            <IconButton color="primary" disabled={(() => {
              if (formData.order_fill_date !== null) return true
              if (formData.confirmed === null) return true
              if (formData.canceled !== null) return true
              return false
            })()}
              onClick={() => setShowFill(true)}>
              <LocalShippingIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Tab Panels */}
        {tabValue === 0 && (
          <Grid container spacing={2} mt={1}>
            {/*<Grid item xs={4}>
              <Box display='flex' flexDirection='row'>
                <DatePicker
                  label="Order Date"
                  value={formData.created_date || ""}
                  fullWidth
                />
                <IconButton onClick={() => { }}><Refresh /></IconButton>
              </Box>
            </Grid>*/}
            <Grid item xs={4}>
              <TextField
                label="Created By"
                value={formData.created_by || ""}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Created At"
                value={new Date(formData.created_at).toLocaleString() || ""}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Partner Name"
                value={formData.partner_name || ""}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Partner Reference"
                value={formData.order_partner_ref || ""}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' flexDirection='row'>
                <TextField
                  label="Delivery Warehouse"
                  value={formData.warehouse_name || ""}
                  fullWidth
                  disabled
                />
                <IconButton onClick={() => { }}><Refresh /></IconButton>
              </Box>
            </Grid>
          </Grid>
        )}
        {tabValue === 1 && (
          <>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Typography variant="h6">Total Order Value: {formData.items.reduce((acc, item) => acc + item.price * item.quantity, 0)}</Typography>
            </Box>
            <TableGrid
              columns={[
                'Item Name',
                'SKU',
                'Item Qty',
                'Item Unit Price',
                'Total Price',
                'Actions',
              ]}
              rowsPerPage={10}
              enableOuterShell={false}
              loading={loadingItems}
            >
              {formData.items.map((item, index) => {

                return (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.item_name}</TableCell>
                    <TableCell align="center">{item.sku}</TableCell>
                    <TableCell>
                      <TextField
                        value={
                          editingQuantities[item.id] !== undefined
                            ? editingQuantities[item.id]
                            : (item.quantity || 1).toFixed(0)
                        }
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setEditingQuantities((prev) => ({
                            ...prev,
                            [item.id]: newValue,
                          }));
                        }}
                        disabled={!enableEdit()}
                        onBlur={() => {
                          if (editingQuantities[item.id] !== undefined) {
                            setFormData((prev) => ({
                              ...prev,
                              items: prev.items.map((i) =>
                                i.id === item.id
                                  ? {
                                    ...i,
                                    quantity: parseInt(editingQuantities[item.id]) || 0,
                                  }
                                  : i
                              ),
                            }));
                            setEditingQuantities((prev) => {
                              const copy = { ...prev };
                              delete copy[item.id];
                              return copy;
                            });
                          }
                        }}
                        type="number"
                        inputProps={{ style: { height: '24px', padding: '0 5px' } }}
                      />

                    </TableCell>
                    <TableCell>
                      <TextField
                        value={editingPrices[item.id] !== undefined ? editingPrices[item.id] : (item.price || 0.0).toFixed(2)}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setEditingPrices((prev) => ({ ...prev, [item.id]: newValue }));
                        }}
                        disabled={!enableEdit()}

                        onBlur={() => {
                          if (editingPrices[item.id] !== undefined) {
                            setFormData((prev) => ({
                              ...prev,
                              items: prev.items.map((i) =>
                                i.id === item.id ? { ...i, price: parseFloat(editingPrices[item.id]) || 0 } : i
                              ),
                            }));
                            setEditingPrices({});
                          }
                        }}
                        type="number"
                        inputProps={{ style: { height: '24px', padding: '0 5px' } }}
                      />

                    </TableCell>
                    <TableCell>{((item.price || 0.0) * item.quantity).toFixed(2)}</TableCell>
                    <TableCell>
                      <IconButton disabled={!enableEdit()}
                        sx={{ padding: 0 }}><RemoveRounded /></IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableGrid>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!enableEdit()}
                sx={{ marginTop: 2 }}
                onClick={() => setshowItemsPicker(true)}
              >
                Add Item
              </Button>
            </Box>
          </>
        )}

        {tabValue === 2 && (
          <OrderWorkFlow orderID={orderID} open={tabValue === 2} />
        )}
        <CRMItemsPickerDialog
          open={showItemsPicker}
          onClose={() => setshowItemsPicker(false)}
          onSelect={(item) => {
            if (item) {
              // Save the item to the order
              addNewItem(item);
            }
          }}
        />
      </Box>
      <LoadingBackdrop open={loading} title={'Loading'} />
      <LoadingBackdrop open={saving} title={'Saving'} />
      <ConfirmDialog open={showConfirm} onClose={() => setShowConfirm(false)} onSave={() => confirmOrder()} title={'Confirm'} descTitle={'Are you sure you want to confirm this order?'} />
      <ConfirmDialog open={showCancel} onClose={() => setShowCancel(false)} onSave={() => cancelOrder()} title={'Cancel'} descTitle={'Are you sure you want to cancel this order?'} />
      <ConfirmDialog open={showFill} onClose={() => setShowFill(false)} onSave={() => handleFillOrder()} title={'Fill'} descTitle={'Are you sure you want to fill this order?'} />
    </CommonDialog >
  );
}

