import React, { useEffect, useState, useCallback, useMemo } from 'react';
import CommonDialog from '../../../Dialogs/CommonDialog';
import { Box, TextField, TableRow, TableCell, TablePagination } from "@mui/material";
import { TableGrid } from "../../../common/TableGrid";
import { debounce } from 'lodash';
import { searchFunction } from '../../../common/search_function';
import axios from 'axios';

export function CRMPartnerGridWithSearchDialog({ open, onClose, onSelect }) {
  return (
    <CommonDialog open={open} onClose={onClose} title="Select Partner">
      <CRMPartnerGridWithSearch onSelect={onSelect} />
    </CommonDialog>
  );
}

export default function CRMPartnerGridWithSearch({ onSelect }) {
  const [partners, setPartners] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); //
  const columns = [
    'Name',
    'Email',
    'Phone'
  ];

  const fetchPartners = async () => {
    setLoading(true);
    try {
      axios.get('/Office/CRM/Partners')
        .then((response) => {
          setPartners(response.data);
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
        });
    } catch (error) {
    }
  };

  const filteredPartners = useMemo(() => {
    return searchFunction(globalSearch, ['partner_name', 'parter_email', 'partner_phone'], partners);
  }, [globalSearch, partners]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  useEffect(() => {
    fetchPartners();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1} padding={1}>
      <TextField
        label={`Search `}
        onChange={(e) => handleSearchChange(e.target.value)}
        fullWidth
        sx={{ bgcolor: 'white', borderRadius: 1, paddingBottom: 2 }}
      />
      <TableGrid columns={columns} loading={loading} rowsPerPage={rowsPerPage} enableOuterShell={false} >
        {filteredPartners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((partner) => (
          <TableRow hover key={partner.id} onClick={() => onSelect(partner)}>
            <TableCell align='center'>{partner.partner_name}</TableCell>
            <TableCell align='center'>{partner.partner_email}</TableCell>
            <TableCell align='center'>{partner.partner_phone}</TableCell>
          </TableRow>
        ))}
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[10, 30, 50]}
        component="div"
        count={partners.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box >
  )
}
