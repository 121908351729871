// SnackbarProvider.jsx
import React, { createContext, useContext } from 'react';
import { SnackbarProvider as NotistackProvider, useSnackbar as useNotistackSnackbar } from 'notistack';

// Create Context
const SnackbarContext = createContext(null);

// Custom Hook
export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

// Inner wrapper to provide showError, showSuccess, etc.
const SnackbarHandler = ({ children }) => {
  const { enqueueSnackbar } = useNotistackSnackbar();

  const showMessage = (message, variant = 'info') => {
    enqueueSnackbar(message, { variant });
  };

  const showSuccess = (message) => showMessage(message, 'success');
  const showError = (message) => showMessage(message, 'error');
  const showInfo = (message) => showMessage(message, 'info');
  const showWarning = (message) => showMessage(message, 'warning');

  return (
    <SnackbarContext.Provider value={{ showSuccess, showError, showInfo, showWarning }}>
      {children}
    </SnackbarContext.Provider>
  );
};

// Exported SnackbarProvider that wraps NotistackProvider and our handler
export const SnackbarProvider = ({ children }) => {
  return (
    <NotistackProvider
      maxSnack={5}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={2000}
      preventDuplicate
    >
      <SnackbarHandler>{children}</SnackbarHandler>
    </NotistackProvider>
  );
};

