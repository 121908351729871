import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from '../../../common/error_provider';
import { NoneManagedCommonDialog } from '../../../Dialogs/CommonDialog';
import LoadingBackdrop from '../../../common/loading_backdrop';
import CheckIcon from '@mui/icons-material/Check';

export default function ItemCheckDialog({ check_id, open, onClose, onSave }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess, showWarning } = useSnackbar();
  const [enableMismatchSave, setEnableMismatchSave] = useState(true);
  useEffect(() => {
    if (!open || !check_id) return;

    setLoading(true);
    axios
      .get(`/Office/CRM/ItemsCheck/${check_id}`)
      .then((res) => {
        const data = res.data.map((item) => ({
          ...item,
          item_qty: Number(item.item_qty),
          found_item_qty: Number(item.found_item_qty),
        }));
        setItems(data);
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open, check_id]);

  const handleFoundQtyChange = (id, value) => {
    setItems((prev) =>
      prev.map((item) =>
        item.id === id
          ? { ...item, found_item_qty: Number(value) || 0 }
          : item
      )
    );
  };

  const handleItemFoundAll = (id) => {
    setItems((prev) =>
      prev.map((item) =>
        item.id === id
          ? { ...item, found_item_qty: item.item_qty }
          : item
      )
    );
  };

  const handleFoundAll = () => {
    setItems((prev) =>
      prev.map((item) => ({
        ...item,
        found_item_qty: item.item_qty,
      }))
    );
  };

  const handleSave = () => {
    setLoading(true);
    axios
      .post(`/Office/CRM/ItemsCheck/${check_id}`, items, {
        params: { enableMismatch: enableMismatchSave ? "1" : enableMismatchSave },
      })
      .then(() => {
        showSuccess('Items saved successfully!');
        onSave();
        onClose();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          if (err.response.data.error_code === 101) {
            showWarning("Please check the items again, or enable mismatch save");
          } else {
            showError(err)
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <NoneManagedCommonDialog open={open} onClose={onClose} title="Check Item" onSave={() => handleSave()}>
      <LoadingBackdrop open={loading} />
      <Box p={2}>
        {items.length === 0 ? (
          <Typography>No items to check.</Typography>
        ) : (
          <>
            <Stack direction="row" spacing={2} mb={2}>
              <Button variant="outlined" onClick={handleFoundAll}>
                Found All
              </Button>
            </Stack>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Expected Qty</TableCell>
                  <TableCell>Found Qty</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => {
                  const mismatch = item.item_qty !== item.found_item_qty;
                  return (
                    <TableRow
                      key={item.id}
                      style={mismatch ? { backgroundColor: '#ffe0e0' } : {}}
                    >
                      <TableCell>{item.item_name}</TableCell>
                      <TableCell>{item.sku}</TableCell>
                      <TableCell>{item.item_qty}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          size="small"
                          value={item.found_item_qty}
                          onChange={(e) =>
                            handleFoundQtyChange(item.id, e.target.value)
                          }
                          inputProps={{ min: 0 }}
                        />
                      </TableCell>
                      <TableCell>{mismatch ? 'Mismatch' : 'OK'}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleItemFoundAll(item.id)}
                          title="Mark as Found"
                        >
                          <CheckIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </Box>
    </NoneManagedCommonDialog>
  );
}

