import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";

const SESSION_TIMEOUT = 1 * 60 * 1000; // 10 minutes
const HEARTBEAT_INTERVAL = 60 * 1000; // 60 seconds

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionToken, setSessionToken] = useState(localStorage.getItem("sessionToken") || null);
  const [enableHeart, setEnableHeart] = useState(false);

  const enableHeartbeat = () => {
    setEnableHeart(true);
  };

  const logout = useCallback(() => {
    if (!enableHeart) return;
    alert("Session expired or logged out!");
    localStorage.removeItem("sessionToken");
    setSessionToken(null);
    setIsAuthenticated(false);
    window.location.href = "/login"; // Redirect to login
  }, []);

  const sendHeartbeat = useCallback(async () => {
    if (!enableHeart) return;
    if (!sessionToken) {
      logout();
      return;
    }

    try {
      const response = await axios.get(
        "/hello",
        {},
        { headers: { Authorization: `Bearer ${sessionToken}` } }
      );

      if (response.status !== 200) {
        logout();
      }
    } catch (error) {
      logout();
    }
  }, [sessionToken, logout]);

  const updateSession = (newToken) => {
    if (newToken) {
      localStorage.setItem("sessionToken", newToken);

      setSessionToken(newToken);
      setIsAuthenticated(true);
    } else {
      logout();
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    let timeout, heartbeatInterval;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(logout, SESSION_TIMEOUT);
    };

    const events = ["mousemove", "keydown", "click", "scroll"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer();
    sendHeartbeat();
    heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);

    return () => {
      clearTimeout(timeout);
      clearInterval(heartbeatInterval);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [
    isAuthenticated,
    logout,
    sendHeartbeat
  ]);

  return (
    <SessionContext.Provider value={{ isAuthenticated, logout, updateSession, enableHeartbeat }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);

