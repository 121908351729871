import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Checkbox, TableCell, TableRow, TablePagination } from '@mui/material';
import axios from 'axios';
import { Viewer, Differ } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { TableGrid } from '../../common/TableGrid';
const THistoryViewer = ({ args }) => {
  const defaultColumns = [{
    label: 'id',
    searchable: true,
    onSearch: (value) => console.log(value),
  }, 'operation', 'tabname', 'ro_comment', 'tstamp'];
  const [history, setHistory] = useState([]);
  const [searchId, setSearchId] = useState('');
  const [oldData, setOldData] = useState(null);
  const [newData, setNewData] = useState(null);
  const [liveData, setLiveData] = useState(true);
  const [additionalColumns, setAdditionalColumns] = useState('');
  const [columns, setColumns] = useState([...defaultColumns]);
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); //

  const fetchHistory = async () => {
    console.debug("Fetching history");
    axios.get(`/Office/Logging/view/${args.org.qry}/${searchId}${liveData ? '?live=1' : ''}`)
      .then((el) => {
        if (el.status == 200) {
          setHistory(el.data);
        }
      })
      .catch((er) => { });
  };


  const handleAddNewColumns = () => {
    const newCols = additionalColumns.split(',').map(col => col.trim()).filter(col => col !== "");
    setColumns([...new Set([...defaultColumns, ...newCols])]); // Avoid duplicate columns
  };

  const viewerProps = {
    indent: 4,
    color: 'black',
    lineNumbers: true,
    highlightInlineDiff: true,
    inlineDiffOptions: { mode: 'word', wordSeparator: ' ' },
    hideUnchangedLines: true,
    syntaxHighlight: true,
    virtual: false,
  };

  const d = new Differ({
    detectCircular: true,
    maxDepth: null,
    showModifications: true,
    arrayDiffMethod: 'lcs',
    ignoreCase: false,
    ignoreCaseForKey: false,
    recursiveEqual: true,
  });

  const diff = d.diff(oldData, newData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const extraColumns = () => {
    return columns.filter(el => !defaultColumns.includes(el) && typeof el === 'string');
  }


  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" style={{ color: 'white' }}>
      <Box flex="1" display="flex" flexDirection="row">
        <Box flex="1" mr={1} mb={1} p={2} color={'black'} style={{ backgroundColor: 'white', borderRadius: '5px' }}>
          <Typography mb={1} variant='body1'>Reporter: {args.org.qry}</Typography>
          <TextField
            label="Search"
            variant="outlined"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            sx={{
              backgroundColor: 'white',
              borderRadius: 1,
              '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: orangeColor } },
            }}
          />
          <Button
            variant="contained"
            sx={{ marginLeft: '10px', backgroundColor: orangeColor, '&:hover': { backgroundColor: 'darkorange' } }}
            onClick={fetchHistory}
          >
            Search
          </Button>

          {/* Live Data Toggle */}
          <Box display="flex" alignItems="center" mt={1}>
            <Typography>Live Data</Typography>
            <Checkbox checked={liveData} onChange={(e) => setLiveData(e.target.checked)} sx={{ color: orangeColor }} />
          </Box>

          {/* Additional Columns Input */}
          <TextField
            label="Additional Columns"
            onChange={(e) => setAdditionalColumns(e.target.value)}
          />
          <Button
            sx={{ marginLeft: '10px', backgroundColor: orangeColor, '&:hover': { backgroundColor: 'darkorange' } }}
            onClick={handleAddNewColumns}
          >
            Add Columns
          </Button>
        </Box>

        {/* JSON Diff Viewer */}
        <Box flex="3" display="flex" flexDirection="row" mb={1}>
          <Box flex="3" p={2} color='black' style={{ backgroundColor: 'white', borderRadius: '5px' }}>
            <Viewer diff={diff} {...viewerProps} />
          </Box>
        </Box>
      </Box>

      {/* Table Section */}
      <Box flex="1">
        <TableGrid columns={columns} loading={false} rowsPerPage={rowsPerPage} enableOuterShell={true}>
          {history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
            let parsedOld = item.old_val ? item.old_val : {};
            let parsedNew = item.new_val ? item.new_val : {};
            return (
              <TableRow
                key={item.id}
                onDoubleClick={() => {
                  setOldData(parsedOld);
                  setNewData(parsedNew);
                }}
                hover
                sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
              >
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.operation}</TableCell>
                <TableCell>{item.tabname}</TableCell>
                <TableCell>{item.ro_comment}</TableCell>
                <TableCell>{new Date(item.tstamp).toLocaleString()}</TableCell>
                {extraColumns().map((col, index) => (
                  <TableCell key={`old-${index}`}>
                    {parsedOld[col] !== undefined ? parsedOld[col] : "-"}
                  </TableCell>
                ))}
                {extraColumns().map((col, index) => (
                  <TableCell key={`new-${index}`}>
                    {parsedNew[col] !== undefined ? parsedNew[col] : "-"}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableGrid>
      </Box>
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={{ ...CommonSX, marginTop: '10px' }}
        count={history.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default THistoryViewer;

