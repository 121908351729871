import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Divider, TextField, Typography, Grid, Button,
  Step, StepLabel, Stepper, List, ListItemButton,
  Paper
} from '@mui/material'
import { DialogSaveButton, DialogCancelButton } from '../../../common/DialogButtons';
import { orangeColor } from '../../../css/common_sx';
import CRMPartnerGridWithSearch from '../partners/partner_grid_with_search';
import CRMWarehouseTableGridWithSearch from '../warehouses/warehouse_grid_with_search';
import axios from 'axios';
export default function CRMCreateNewOrder({ open, onClose, onNew }) {
  const [activeStep, setActiveStep] = useState(0);
  const [partner, setPartner] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [ordertypes, setOrderTypes] = useState([]);
  const updateOrderDetails = (key, value) => {
    setOrderDetails({ ...orderDetails, [key]: value });
  }

  useEffect(() => {
    if (open) {
      loadOrderTypes();
      setPartner(null);
      setOrderDetails({});
      setActiveStep(0);
    }
  }, [open]);

  const loadOrderTypes = () => {
    axios.get('/Office/CRM/Orders/OrderTypes').then((response) => {
      setOrderTypes(response.data);
    }).catch((err) => {

    });
  }

  const saveOrder = () => {
    if (partner && warehouse) {
      axios.post('/Office/CRM/Orders', {
        partner_id: partner.id,
        warehouse_id: warehouse.id,
        order_type_id: orderDetails.order_type_id
      })
        .then((response) => {
          if (response.status === 200) {
            axios.get(`/Office/CRM/Orders/${response.data.id}`)
              .then((response) => {
                onNew(response.data);
                onClose();
              })
              .catch((err) => { });
          }
        }).catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ backgroundColor: orangeColor, color: 'white' }}>Create New Order (This is a plan not final Till you Confirm the order)</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Stepper activeStep={activeStep} sx={{ marginBottom: 1 }}>
            <Step >
              <StepLabel>Choose Type</StepLabel>
            </Step>
            <Step >
              <StepLabel>Choose Partner</StepLabel>
            </Step>
            <Step>
              <StepLabel>Delivery Warehouse</StepLabel>
            </Step>
            <Step>
              <StepLabel>Order Summary</StepLabel>
            </Step>
          </Stepper>
          <Divider />
          <Box mt={2}>
            {activeStep === 0 &&
              <Box display="flex" flexDirection="row" gap={2}>
                {/* Order Type List */}
                <Box flex={1}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Order Types
                    </Typography>
                    <List>
                      {ordertypes.map((ordertype) => (
                        <ListItemButton
                          key={ordertype.id}
                          onClick={() =>
                            setOrderDetails({ ...orderDetails, order_type_id: ordertype.id })
                          }
                          sx={{
                            borderRadius: 1,
                            "&:hover": { bgcolor: "primary.light", color: "white" },
                            transition: "0.3s",
                          }}
                        >
                          <Typography variant="body1">{ordertype.type_name}</Typography>
                        </ListItemButton>
                      ))}
                    </List>
                  </Paper>
                </Box>

                {/* Order Type Details */}
                <Box flex={1}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    {(
                      (() => {
                        const selectedOrderType = ordertypes.find(
                          (el) => el.id === orderDetails.order_type_id
                        );

                        return (
                          <>
                            <Typography variant="h6" gutterBottom color="primary">
                              {selectedOrderType?.type_name || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Description:
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 1 }}>
                              {selectedOrderType?.description || "N/A"}
                            </Typography>

                            <Typography variant="body2" color="textSecondary">
                              Duration:
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 1 }}>
                              {selectedOrderType?.duration || "N/A"}
                            </Typography>

                            <Typography variant="body2" color="textSecondary">
                              Workflow:
                            </Typography>
                            <Typography variant="body1">
                              {selectedOrderType?.workflow || "N/A"}
                            </Typography>
                          </>
                        );
                      })()
                    )}
                  </Paper>
                  <Button disabled={!orderDetails.order_type_id} onClick={() => setActiveStep(1)} variant="contained" color="primary" sx={{ mt: 2 }}>
                    Next
                  </Button>
                </Box>
              </Box>
            }
            {activeStep === 1 && <CRMPartnerGridWithSearch onSelect={(partner) => {
              setPartner(partner);
              updateOrderDetails('partner_id', partner.id);
              setActiveStep(2);
            }} />}
            {activeStep === 2 &&
              <CRMWarehouseTableGridWithSearch onSelect={(warehouse) => {
                updateOrderDetails('warehouse_id', warehouse.id);
                setWarehouse(warehouse);
                setActiveStep(3);
              }} />
            }
            {activeStep === 3 &&
              <Box>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">Selected Partner</Typography>
                  <Typography>{partner.partner_name}</Typography>
                  <Typography>{partner.partner_email}</Typography>
                  <Typography>{partner.partner_phone}</Typography>
                </Paper>
                <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
                  <Typography variant="h6">Delivery Warehouse</Typography>
                  <Typography>{warehouse.name}</Typography>
                  <Typography>{warehouse.full_address}</Typography>
                </Paper>
              </Box>
            }
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={onClose} />
        <DialogSaveButton onClick={saveOrder} disabled={activeStep !== 3} />
      </DialogActions>
    </Dialog >
  )
}
