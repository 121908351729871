import React, { createContext, useEffect, useState, useCallback } from "react";
import mqtt from "mqtt";
import mitt from "mitt";

// Create Context
const MqttContext = createContext();

// Initialize event emitter
const mqttEmitter = mitt();

export const MqttProvider = ({ children, brokerUrl = "wss://servicem.leviathan.systems/mqtt" }) => {
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl);

    mqttClient.on("connect", () => {
      console.log("✅ Connected to MQTT Broker");
      setIsConnected(true);
      mqttEmitter.emit("connected", true);
    });

    mqttClient.on("message", (topic, message) => {
      const msgString = message.toString();
      console.log(`📩 Received: ${msgString} on ${topic}`);
      mqttEmitter.emit("message", { topic, message: msgString });
    });

    mqttClient.on("error", (err) => {
      console.error("❌ MQTT Connection Error:", err);
      mqttEmitter.emit("error", err);
    });

    mqttClient.on("close", () => {
      console.log("🔌 MQTT Disconnected");
      setIsConnected(false);
      mqttEmitter.emit("disconnected", true);
    });

    setClient(mqttClient);

    return () => {
      mqttClient.end();
    };
  }, [brokerUrl]);

  // Subscribe to a topic
  const subscribe = useCallback((topic) => {
    if (client && isConnected) {
      client.subscribe(topic, (err) => {
        if (err) {
          console.error(`⚠️ Subscription failed: ${topic}`, err);
          mqttEmitter.emit("error", err);
        } else {
          console.log(`✅ Subscribed to ${topic}`);
          mqttEmitter.emit("subscribed", topic);
        }
      });
    }
  }, [client, isConnected]);

  // Unsubscribe from a topic
  const unsubscribe = useCallback((topic) => {
    if (client && isConnected) {
      client.unsubscribe(topic, (err) => {
        if (err) {
          console.error(`⚠️ Unsubscribe failed: ${topic}`, err);
          mqttEmitter.emit("error", err);
        } else {
          console.log(`🚫 Unsubscribed from ${topic}`);
          mqttEmitter.emit("unsubscribed", topic);
        }
      });
    }
  }, [client, isConnected]);

  // Publish a message
  const publish = useCallback((topic, message) => {
    if (client && isConnected) {
      client.publish(topic, message, (err) => {
        if (err) {
          console.error(`⚠️ Publish failed: ${topic}`, err);
          mqttEmitter.emit("error", err);
        } else {
          console.log(`📤 Published to ${topic}: ${message}`);
          mqttEmitter.emit("published", { topic, message });
        }
      });
    }
  }, [client, isConnected]);

  return (
    <MqttContext.Provider value={{ subscribe, unsubscribe, publish, isConnected, mqttEmitter }}>
      {children}
    </MqttContext.Provider>
  );
};

export const useMqtt = () => React.useContext(MqttContext);

