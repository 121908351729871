import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  TextField,
  Button,
  Tab,
  Tabs,
  Grid,
  Paper,
  Divider,
  CircularProgress,
  Select, MenuItem, InputLabel, FormControl, Typography
} from '@mui/material';
import ItemsTable from './items_table';
import { orangeColor } from '../../../css/common_sx';
import axios from 'axios';
import LoadingBackdrop from '../../../common/loading_backdrop';
import { debounce } from 'lodash';
import ViewDocumentItemDetail from './view_document_item_detail';
import CommonDialog from '../../../Dialogs/CommonDialog';
import { useSnackbar } from '../../../common/error_provider';

function DocumentTab({ doc }) {
  const [formData, setFormData] = useState({});
  const [documentItems, setDocumentItems] = useState([]);
  const [showDocumentItemDialog, setShowDocumentItemDialog] = useState(false);
  const [selectedDocumentItem, setSelectedDocumentItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showError } = useSnackbar();
  useEffect(() => {
    if ((doc) && (doc !== null)) {
      setFormData({});
      setDocumentItems([]);
      fetchDocument();
      fetchDocumentItems();
    }
  }, [doc]);


  const fetchDocument = async () => {
    axios.get(`/Office/CRM/Documents/${doc}`)
      .then((res) => {
        if (res.status === 200) {
          let document = res.data;
          setFormData({
            id: document.id || '',
            document_no: document.document_no || '',
            created_by: document.created_by || '',
            created_at: document.created_at ? new Date(document.created_at).toLocaleString() : '',
            partner_reference: document.partner_reference || '',
            document_type_name: document.document_type_name || '',
            source_warehouse_name: document.source_warehouse_name || '',
            destination_warehouse_name: document.destination_warehouse_name || '',
            deliveryFrom: {
              id: -1,
              name: ''
            },
            deliveryTo: {
              id: -1,
              name: ''
            },
            required_amount: document.require_amount || false,
            status: parseInt(document.status || '-1'),
            status_name: document.status_name || '',
            description: document.description || ''
          });
        }
      })
      .catch((err) => {
        showError('Error fetching document');
      });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setFormData((prevState) => {
      if (name === 'status') {
        const status_name = availableNewStatuses.find((status) => status.status_value === value).status_name;
        return { ...prevState, status_name };
      }
      return prevState;
    });
  };

  const fetchDocumentItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/Office/CRM/DocumentItems/${doc}`);
      setDocumentItems(response.data.map((item) => ({
        ...item,
        total: item.gross * item.quantity
      })));
      setLoading(false);
    } catch (error) {
      showError('Error fetching document items');
      console.error(error);
    } finally {
    }
  };

  return (
    <>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9} >
            <TextField
              label="Document Number"
              name="document_no"
              value={formData.document_no || ""}
              fullWidth
              disabled
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={3} >
            <TextField
              label="Status"
              name="status"
              value={formData.status || ""}
              fullWidth
              disabled
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Created By"
              name="created_by"
              value={formData.created_by || ""}
              fullWidth
              disabled
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Created At"
              name="created_at"
              value={formData.created_at || ""}
              fullWidth
              disabled
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
              onChange={handleInputChange}
            />
          </Grid>
          {formData.partner_reference && (
            <Grid item xs={12} sm={3}>
              <TextField
                label="Document Partner Reference"
                name="partner_reference"
                value={formData.partner_reference || ""}
                fullWidth
                disabled
                variant="filled"
                InputProps={{ sx: { borderRadius: 1 } }}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Document Type"
              name="document_type_name"
              value={formData.document_type_name || ""}
              fullWidth
              disabled
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Source Warehouse"
              name="source_warehouse_name"
              value={formData.source_warehouse_name || ""}
              fullWidth
              disabled
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Destination Warehouse"
              name="destination_warehouse_name"
              value={formData.destination_warehouse_name || ""}
              fullWidth
              disabled
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={24}>
            <TextField
              value={formData.description || ""}
              fullWidth multiline
              inputProps={{ readOnly: true }}
              rows={4}
              variant="outlined" />
          </Grid>
        </Grid>
      </Paper>
      <Divider sx={{ my: 2 }} />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box overflow='auto'>
          <ItemsTable
            items={documentItems}
            allowDecrement={false}
            requireAmount={formData.required_amount}
            showWarehouseName={true}
            allowRemove={false}
            allowAmountChange={false}
            onClickItemInfo={(item) => {
              setSelectedDocumentItem({ document: formData, ...item });
              setShowDocumentItemDialog(true);
            }}
          />
        </Box>
      )}
      <ViewDocumentItemDetail
        open={showDocumentItemDialog}
        onClose={() => setShowDocumentItemDialog(false)}
        item={selectedDocumentItem} />
    </>
  )
}

export default function CRMViewDocument({ open, onClose, document }) {
  const [showLoadingBackdrop, setShowLoadingBackdrop] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { showError } = useSnackbar();
  useEffect(() => {
    if (open) {
      setSelectedDocument(null);
      setDocuments([]);
      axios.get(`/Office/CRM/Documents/Batch/byDocID/${document}`)
        .then((res) => {
          if (res.status === 200) {
            setDocuments(res.data);
            if (res.data.length > 0) {
              setSelectedDocument(res.data[0].id);
            }
          }
        })
        .catch((err) => {
          showError('Error fetching documents batch');
        });
    }
  }, [open]);


  const handleDeleteDocument = async () => {
    setShowLoadingBackdrop(true);
    try {
      axios.delete(`/Office/CRM/Documents/${document.id}`)
        .then((res) => {
          if (res.status === 200) {
            setShowLoadingBackdrop(false);
          }
        }).catch((err) => {
          console.error(err);
        })
      onClose();
    } catch (error) {
      setShowLoadingBackdrop(false);
      console.error(error);
    }
  };

  return (
    <CommonDialog open={open} onClose={() => onClose()} enableSave={false} title="Edit Document" >
      <Box>
        <Tabs variant="scrollable" mb={1} onChange={(event, value) => setSelectedDocument(value)}>
          {documents.map((doc) => (
            <Tab key={doc.id} value={doc.id}
              label={
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body2" fontWeight="bold">
                    {doc.document_no}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {doc.source_warehouse} → {doc.dst_warehouse}
                  </Typography>
                </Box>
              }
              sx={{
                minWidth: 120, // Ensures uniform width
                maxWidth: 200,
                textTransform: "none",
                paddingY: 1,
                "&.Mui-selected": {
                  color: "primary.main",
                  fontWeight: "bold",
                },
              }}
            />
          ))}
        </Tabs>
        <DocumentTab doc={selectedDocument} />
      </Box>
    </CommonDialog>
  );
}

