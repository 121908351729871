import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Chip,
  TableRow,
  TableCell,
  TablePagination,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import AddIcon from '@mui/icons-material/Add';
import { StorageRounded } from '@mui/icons-material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { searchFunction } from '../../common/search_function';
import { debounce } from 'lodash';
import { TableGrid } from '../../common/TableGrid';
import CRMCreateViewItem from './items/create_item';


const CRMItems = () => {
  const [items, setItems] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');

  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); // Items per page

  const [showCreateItem, setShowCreateItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingItems, setLoadingItems] = useState(true);

  const fetchItems = async () => {
    setLoadingItems(true);
    axios.get('/Office/CRM/Items')
      .then(response => {
        setItems(response.data);
        setLoadingItems(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching data');
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleNewItem = (item) => {
    setItems(prevItems => [item, ...prevItems]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredItems = useMemo(() => {
    return searchFunction(globalSearch, ['item_name', 'sku', ' item_type', 'description'], items);
  }, [globalSearch, items]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const columns = [
    'SKU',
    'Name',
    'Tags',
    'Type',
    'Actions'
  ]

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1}>
      {/* Filter Component */}
      <Box display="flex" justifyContent="space-between" mb={1} bgcolor={orangeColor} p={1} borderRadius={1}>
        <TextField
          label="Search by Name"
          variant="outlined"
          fullWidth
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={{ bgcolor: 'white', borderRadius: 1 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedItem(null);
            setShowCreateItem(true);
          }}
          sx={{ ml: 1 }}
        >
          Add Item
        </Button>
      </Box>
      <TableGrid columns={columns} loading={loadingItems} rowsPerPage={rowsPerPage}>
        {filteredItems
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.sku}</TableCell>
              <TableCell>{row.item_name}</TableCell>
              <TableCell>
                {row.tags.map(tag => (
                  <Chip size="small" variant="outlined" key={tag} label={tag} sx={{ marginLeft: '5px', padding: 0 }} />
                ))}
              </TableCell>
              <TableCell>
                {row.item_type}
              </TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  sx={{
                    padding: 0, // Remove extra padding
                  }}
                  onClick={() => {
                    setSelectedItem(row.id);
                    setShowCreateItem(true);
                  }}>
                  <InfoIcon fontSize='small' />
                </IconButton>
                <IconButton
                  size="small"
                  sx={{
                    padding: 0, // Remove extra padding
                  }}
                  onClick={() => handleDelete(row.id)}>
                  <DeleteRounded fontSize='small' />
                </IconButton>
                <IconButton
                  size="small"
                  sx={{
                    padding: 0, // Remove extra padding
                  }}
                >
                  <StorageRounded fontSize='small' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableGrid>

      <TablePagination
        rowsPerPageOptions={[30, 60, 100]}
        component="div"
        sx={{ ...CommonSX, marginTop: '10px' }}
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CRMCreateViewItem
        open={showCreateItem}
        onClose={() => setShowCreateItem(false)}
        onNew={handleNewItem}
        item_id={selectedItem} />
    </Box >
  );
};


export default CRMItems;

