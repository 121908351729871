import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MenuProvider } from './common/menu_navigator';
import ReactGA from 'react-ga';
import { SnackbarProvider } from './common/error_provider';
import { DialogSettingsProvider } from './common/session_storage';
import { SessionProvider } from './common/session_heartbeat';
ReactGA.initialize('G-JNCKDHEJ7P', { debug: true });
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <MenuProvider>
      <SnackbarProvider>
        <DialogSettingsProvider>
          <SessionProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </SessionProvider>
        </DialogSettingsProvider>
      </SnackbarProvider>
    </MenuProvider>
  </React.StrictMode>
);
//root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
