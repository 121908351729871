import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Divider,
  Select,
  MenuItem,
  Grid,
  Modal,
  InputLabel,
  FormControl
} from '@mui/material';
import axios from 'axios';
import { Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useSnackbar } from '../../../common/error_provider';
import CommonDialog from '../../../Dialogs/CommonDialog';
import LoadingBackdrop from '../../../common/loading_backdrop';
import { string_to_url } from '../../../common/img_proxy_helper';
export default function CRMCreateViewItem({ open, onClose, onNew, item_id }) {
  const [tabValue, setTabValue] = useState(0);
  const [itemTypes, setItemTypes] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    type: '',
    description: '',
  });
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const { showError, showInfo } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [loadingItemTypes, setLoadingItemTypes] = useState(true);

  const fetchItemTypes = () => {
    axios.get('/Office/CRM/ItemTypes')
      .then(response => {
        setItemTypes(response.data);
        setLoadingItemTypes(false);
      })
      .catch(() => showError('Error loading Item Types'));
  }

  const fetchItem = () => {
    axios.get(`/Office/CRM/Items/${item_id}`)
      .then(response => {
        setLoading(false);
        setFormData(response.data);
      })
      .catch(er => {
        setLoading(false);
        showError('Error loading item');
      })

  }

  useEffect(() => {
    if (!open) return;
    setFormData({});
    setImages([]);
    fetchItemTypes();
    if (item_id) { fetchItem(); loadImages(item_id); } else { setLoading(false); setFormData({}); }
  }, [open]);


  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const handleInputChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append('file', file);
    setImages(prevFormData => (
      [...prevFormData, { new: true, name: URL.createObjectURL(file), file: file }]
    ));
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setImageModalOpen(true);
  };

  const handleImageDelete = (imageToDelete) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      photos: prevFormData.photos.filter(image => image !== imageToDelete)
    }));
  };

  const handleSave = () => {

    axios.post('/Office/CRM/Items', formData)
      .then(response => {
        if (response.status === 200) {
          setFormData(response.data);
          if (!formData.id) {
            onNew(response.data);
          }
          for (const im of images) {
            if (im.new !== undefined) {
              const data = new FormData();
              data.append('file', im.file);
              axios.post(`/Office/Files/Upload/pcrms-crm-items?path=${response.data.id}/`, data)
                .then((res) => {

                })
                .catch((err) => { })
            }
          }
          onClose();
          showInfo("Saved Item");
        }
      })
      .catch(error => {
        showError('Error saving item');
      });
  };
  const loadImages = (img_id) => {
    axios.get(`/Office/Files/Browse/pcrms-crm-items?path=${img_id}/`)
      .then((res) => {
        if (res.status === 200) {
          setImages(res.data);;
        }
      })
      .catch((err) => { });
  }

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={item_id ? `Edit Item ${formData.item_name}` : 'Create New Item'}
      onSave={handleSave}
    >
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="General" />
            <Tab label="Locations" />
            <Tab label="Access" />
          </Tabs>
        </Box>
        {tabValue === 0 && (
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <TextField
                  label="Name"
                  name="item_name"
                  value={formData.item_name || ""}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Created By"
                  name="sku"
                  value={formData.created_by || ""}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select
                    labelId="type-label"
                    name="item_type_id"
                    value={formData.item_type_id}
                    onChange={handleInputChange}
                    label="Type"
                  >
                    <MenuItem value="-1"><em>Select Type</em></MenuItem>
                    {itemTypes.map(type => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.type_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="SKU"
                  name="sku"
                  value={formData.sku || ""}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  inputProps={{ readOnly: !!item_id }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="Created At"
                  name="created_at"
                  value={formData.created_at || ""}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Product URL"
                  name="product_url"
                  value={formData.product_url || ""}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={formData.description || ""}
                  onChange={handleInputChange}
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Photos</Typography>
                <Button variant="contained" component="label">
                  Upload Photo
                  <input type="file" hidden onChange={handleImageUpload} />
                </Button>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                  {(images || []).map((photo, index) => (
                    <Box key={index} sx={{ position: 'relative', display: 'inline-block' }}>
                      <img
                        src={photo.new !== undefined ? photo.name : string_to_url('pcrms-crm-items', photo.name, 100, 100)}
                        alt={`Uploaded ${index + 1}`}
                        style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
                        onClick={() => handleImageClick(photo)}
                      />
                      <IconButton
                        sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                        onClick={() => handleImageDelete(photo)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {tabValue === 1 && (
          <Box>
            <Typography>Locations</Typography>
            {/* Add form fields for locations here */}
          </Box>
        )}

        {tabValue === 2 && (
          <Box>
            <Typography>Access</Typography>
            {/* Add form fields for access here */}
          </Box>
        )}
      </Box>

      {/* Image Modal */}
      <Modal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box sx={{ position: 'relative', width: '80%', height: '80%' }}>
          <img
            src={selectedImage ? selectedImage.new !== undefined ? selectedImage.name : string_to_url('pcrms-crm-items', selectedImage.name, 1280, 1024) : ''}
            alt="Enlarged"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
          <IconButton
            sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }}
            onClick={() => setImageModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
      <LoadingBackdrop open={loading || loadingItemTypes} title={'Loading Item'} />
    </CommonDialog>
  );
}

