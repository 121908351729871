import React, { useEffect, useState } from 'react';
import {
  Box, Divider, Typography, Grid, Paper, List,
  ListItemButton, ListItemText, CircularProgress, Table, TableBody,
  IconButton, TableCell, TableContainer, TableHead, TableRow,
  ListItem
} from '@mui/material';
import { orangeColor } from '../../../css/common_sx';
import axios from 'axios';
import ItemCheckDialog from '../items/item_check';
import LoadingBackdrop from '../../../common/loading_backdrop';
import OrderWorkFlowMovementTo from './workflow_movement';
import { useSnackbar } from '../../../common/error_provider';
export default function OrderWorkFlow({ open, orderID }) {
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [workflowItems, setWorkflowItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showItemCheck, setShowItemCheck] = useState(false);
  const [workflowrunref, setWorkflowRunRef] = useState(null);
  const [showRunningFlowItem, setShowRunningFlowItem] = useState(false);
  const [showWarehousesDialog, setShowWarehousesDialog] = useState(false);
  const [selectedWorkflowItem, setSelectedWorkflowItem] = useState(null);

  const { showError } = useSnackbar();

  useEffect(() => {
    if (!open || !orderID) return;

    const fetchWorkflows = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`/Office/CRM/OrderWorkflows/${orderID}`);
        setWorkflows(res.data);
        if (res.data.length > 0) {
          setSelectedWorkflow(res.data[0]);
        }
      } catch (err) {
        console.error('Error fetching workflows:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflows();
  }, [open, orderID]);

  const fetchItems = async () => {
    try {
      const res = await axios.get(`/Office/CRM/OrderWorkflows/WorkflowItems/${selectedWorkflow.id}`);
      setWorkflowItems(res.data);
    } catch (err) {
      console.error('Error fetching workflow items:', err);
    }
  };



  useEffect(() => {
    if (!selectedWorkflow?.id) return;
    fetchItems();
  }, [selectedWorkflow]);


  const runTaskflowItem = (args = {}) => {
    setShowRunningFlowItem(true);
    axios.post(`/Office/CRM/OrderWorkflows/WorkflowItems/${selectedWorkflow.id}/run/${selectedWorkflowItem.workflow_item_id}`, args)
      .then((response) => {
        if (response.status === 200) {
          setShowRunningFlowItem(false);
          if (response.data.finished_at === null) {
            if (response.data.action === 'check') {
              setWorkflowRunRef(response.data.run_remote_ref);
              setShowItemCheck(true);
            }
            if (response.data.action === 'movement_to') {
              setShowWarehousesDialog(true);
              setWorkflowRunRef(response.data.whole_action.dst_tags);
            }
          } else {
            fetchItems();
          }
        }
      })
      .catch((err) => {
        setShowRunningFlowItem(false);
        showError('Error running workflow item');
      });

  }

  useEffect(() => {
    if (selectedWorkflowItem !== null) {
      runTaskflowItem();
    }
  }, [selectedWorkflowItem])

  const handleItemCheck = (item) => {
    if (item === selectedWorkflowItem) {
      runTaskflowItem();
    } else {
      setSelectedWorkflowItem(item);
    }
  }

  if (!open) return null;

  return (
    <Box mt={1}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper elevation={3}>
              <Typography variant="h6" sx={{ p: 2, color: orangeColor }}>
                Workflows
              </Typography>
              <Divider />
              <List dense>
                {workflows.map((wf) => (
                  <ListItemButton
                    key={wf.id}
                    selected={wf.id === selectedWorkflow?.id}
                    onClick={() => setSelectedWorkflow(wf)}
                  >
                    <ListItem>
                      <CircularProgress variant="determinate" size={15} value={10} />
                      <Typography>{wf.workflow_name}</Typography>
                    </ListItem>
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Workflow Steps: {selectedWorkflow?.workflow_name}
              </Typography>
              <Divider sx={{ mb: 2 }} />

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Action Name</TableCell>
                      <TableCell>Enabled</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workflowItems.map((item, index) => {
                      // Step is available if it's the first or all previous steps are enabled
                      let isAvailable =
                        index === 0 ||
                        workflowItems
                          .slice(0, index)
                          .every((prev) => prev.finished_at);
                      if (isAvailable) {
                        isAvailable = item.finished_at === null;
                      }
                      return (
                        <TableRow
                          hover={isAvailable}
                          key={item.workflow_item_id}
                          onClick={() => {
                            if (isAvailable) handleItemCheck(item);
                          }}
                          sx={{
                            opacity: isAvailable ? 1 : 0.4,
                            pointerEvents: isAvailable ? 'auto' : 'none',
                            cursor: isAvailable ? 'pointer' : 'default',
                          }}
                        >
                          <TableCell>{isAvailable ? 'Ready' : item.finished_at !== null ? 'Finished' : 'Pending'}</TableCell>
                          <TableCell>
                            {item.workflow_action.desc
                              ? item.workflow_action.desc
                              : item.workflow_action?.action.replace(/_/g, ' ')}
                          </TableCell>
                          <TableCell>{item.workflow_enabled ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                      );
                    })}
                    {workflowItems.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No steps defined.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}
      <ItemCheckDialog
        open={showItemCheck}
        onClose={() => setShowItemCheck(false)}
        check_id={workflowrunref}
        onSave={() => { runTaskflowItem(null, true) }}
      />
      <LoadingBackdrop open={showRunningFlowItem} text={"Running Workflow Item"} />
      <OrderWorkFlowMovementTo
        open={showWarehousesDialog}
        workflowItemID={selectedWorkflowItem?.workflow_item_id}
        onClose={() => setShowWarehousesDialog(false)}
        onSave={(dc) => { runTaskflowItem({ document_id: dc.document_id }) }}
        orderWorkFlowID={selectedWorkflow?.id}
      />
    </Box>
  );
}

