import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box, Button, Typography,
  TextField, TableCell, TableRow, MenuItem, Select, InputLabel, FormControl, Breadcrumbs, Link,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import { orangeColor, CommonSX } from "../css/common_sx";
import { TableGrid } from '../common/TableGrid';
import debounce from 'lodash/debounce';
import { searchFunction } from "../common/search_function";

const LeviathanLevels = [
  { level: 1, color: "#FF0000", name: "Emergency", icon: "❗" },
  { level: 2, color: "#FF4500", name: "Alert", icon: "🚨" },
  { level: 3, color: "#FF8C00", name: "Critical", icon: "⚡" },
  { level: 4, color: "#FFD700", name: "Error", icon: "⚠️" },
  { level: 5, color: "#FFFF00", name: "Warning", icon: "⚠️" },
  { level: 6, color: "#00FFFF", name: "Notice", icon: "ℹ️" },
  { level: 7, color: "#87CEEB", name: "Info", icon: "ℹ️" },
  { level: 8, color: "#00FF00", name: "Debug", icon: "✅" }
];

const SystemTasks = () => {
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [levelFilter, setLevelFilter] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);

  const [messageFilter, setMessageFilter] = useState("");

  const [globalSearch, setGlobalSearch] = useState('');

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(60); //
  // Fetch available apps for the first step


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredLog = useMemo(() => {
    return searchFunction(globalSearch,
      ['app_name', 'message', 'alias'], data)
  }, [globalSearch, data]);

  // Debounce the search input to avoid filtering on every keystroke
  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);


  useEffect(() => {
    const fetchApps = async () => {
      try {
        const response = await axios.get("/LeviathanMonitoring/log/getApps");
        setApps(response.data);
      } catch (error) {
        console.error("Error fetching apps:", error);
      }
    };
    fetchApps();
  }, []);

  useEffect(() => {
    if ((selectedApp) && (levelFilter.length > 0)) {
      fetchData();
    }
  }, [selectedApp, levelFilter]);

  const fetchData = () => {
    setLoading(true);
    axios.get(`/LeviathanMonitoring/log?date=${selectedDate}&app_id=${selectedApp}&levels=${levelFilter.join(",")}`)
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => { });
  };

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Box display="flex" flexDirection='column' borderRadius={2} boxShadow={3} bgcolor={orangeColor} p={1} mb={1}>
        <Box display='flex' sx={{ flexDirection: 'row' }}>
          <TextField
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            label="Select Date"
            variant="outlined"
          />
          <TextField
            label="Search Message"
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
          />
          <FormControl>
            <InputLabel>Select Application</InputLabel>
            <Select
              value={selectedApp}
              onChange={(value) => setSelectedApp(value.target.value)}
            >
              {apps.map((app) => (
                <MenuItem key={app.id} value={app.id}>
                  {app.application_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Select Levels</InputLabel>
            <Select
              multiple
              value={levelFilter}
              onChange={(value) => setLevelFilter(value.target.value)}
              renderValue={(selected) => selected.map(level => LeviathanLevels.find(l => l.level === level)?.name).join(", ")}
            >
              {LeviathanLevels.map((level) => (
                <MenuItem key={level.level} value={level.level}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableGrid
        columns={["Icon", "ID", "App Name", "Message", "Alias", "Occurrence Count", "Last Occurrence", "First Occurrence"]}
        loading={loading} >
        {filteredLog
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <TableRow key={index}>
              <TableCell>{LeviathanLevels.find(l => l.level === row.level)?.icon}</TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.app_name}</TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell>{row.alias}</TableCell>
              <TableCell>{row.occurrence_count}</TableCell>
              <TableCell>{new Date(row.last_occurrence_time).toLocaleString()}</TableCell>
              <TableCell>{new Date(row.first_occurrence_time).toLocaleString()}</TableCell>
            </TableRow>
          ))}
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={{ ...CommonSX, marginTop: '10px' }}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box >
  );
};

export default SystemTasks;

