import React, { useState, useEffect } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import CommonDialog from './CommonDialog';

export default function ConfirmDialog({ open, onClose, onSave, title, descTitle = '' }) {
  return (
    <CommonDialog open={open} onClose={onClose} title={title} onSave={() => {
      if (onSave) onSave();
      if (onClose) onClose();
    }}>
      <Box p={2}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h5" fontWeight="bold" textAlign="center">
            {title}
          </Typography>
          {descTitle && (
            <Typography variant="body1" color="text.secondary" textAlign="center">
              {descTitle}
            </Typography>
          )}
        </Stack>
      </Box>
    </CommonDialog>
  );
}
